<template>
    <v-dialog v-model="viewOps.dialog" max-width="550px">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>View Customer</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-tabs hide-slider v-model="tab">
                                <v-tab v-for="tab in tabs" :key="tab">
                                    <span class="avsb-tab-title">{{
                                        tab
                                    }}</span>
                                </v-tab>

                                <!-- Customer Profile tab -->
                                <v-tab-item transition="false">
                                    <TabCustomerProfile />
                                </v-tab-item>

                                <!-- Settings tab -->
                                <v-tab-item transition="false">
                                    <TabCustomerClients />
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    components: {
        TabCustomerProfile: () =>
            import("@/components/admin/tabs/tabCustomerProfile"),
        TabCustomerClients: () =>
            import("@/components/admin/tabs/tabCustomerClients"),
    },
    computed: {
        ...mapState("customersAdminMgmt", ["viewOps"]),
    },
    data: () => ({
        tabs: ["profile", "clients"],
        tab: 0,
    }),
    methods: {
        hideDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
            this.$store.commit("customersAdminMgmt/FLUSH_ITEM");
        },
    },
    name: "DialogCustomerView",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
